@use '@angular/material' as mat;
$mist-blue-palette: (
    50: #e2f6fc,
    100: #b4e7f8,
    200: #84d7f4,
    300: #57c7f0,
    400: #36baee,
    500: #1baeec,
    600: #15a0de,
    700: #0b8dca,
    800: #0a7cb7,
    900: #015c95,
    contrast: (50: rgba(black, 0.87), 100: rgba(black, 0.87), 200: rgba(black, 0.87), 300: rgba(black, 0.87), 400: rgba(black, 0.87), 500: rgba(black, 0.87), 600: rgba(black, 0.87), 700: white, 800: white, 900: white)
);
$mist-cyan-palette: (
    50: #e0f8f7,
    100: #b3edeb,
    200: #82e1e0,
    300: #50d4d5,
    400: #29cace,
    500: #00c1c9,
    600: #00b1b7,
    700: #009b9e,
    800: #018787,
    900: #05635e,
    contrast: (50: rgba(black, 0.87), 100: rgba(black, 0.87), 200: rgba(black, 0.87), 300: rgba(black, 0.87), 400: rgba(black, 0.87), 500: rgba(black, 0.87), 600: rgba(black, 0.87), 700: white, 800: white, 900: white)
);
$mist-green-palette: (
    50: #f1f8e5,
    100: #ddedbf,
    200: #c5e195,
    300: #add669,
    400: #99cd43,
    500: #86c410,
    600: #76b403,
    700: #60a000,
    800: #4a8d00,
    900: #1a6b00,
    contrast: (50: rgba(black, 0.87), 100: rgba(black, 0.87), 200: rgba(black, 0.87), 300: rgba(black, 0.87), 400: rgba(black, 0.87), 500: rgba(black, 0.87), 600: rgba(black, 0.87), 700: white, 800: white, 900: white)
);
$mist-red-palette: (
    50: #f9e7e9,
    100: #f0c3c5,
    200: #d58a86,
    300: #c05e5a,
    400: #c33d35,
    500: #c02918,
    600: #b31f18,
    700: #a31514,
    800: #960d0d,
    900: #870101,
    contrast: (50: rgba(black, 0.87), 100: rgba(black, 0.87), 200: rgba(black, 0.87), 300: rgba(black, 0.87), 400: white, 500: white, 600: white, 700: white, 800: white, 900: white)
);
$mist-primary: mat.define-palette($mist-blue-palette, 900, 300, 900);
$mist-accent: mat.define-palette($mist-green-palette, 800, 300, 900);
$mist-warn: mat.define-palette($mist-red-palette, 800, 300, 900);


            
$mist-typography-styles-headline1: mat.define-typography-level(
  $font-family: unquote("Roboto"),
  $font-size: 34px
);
$mist-typography-styles-headline2: mat.define-typography-level(
  $font-family: unquote("Roboto"),
  $font-size: 30px
);
$mist-typography-styles-headline3: mat.define-typography-level(
  $font-family: unquote("Roboto"),
  $font-size: 24px
);
$mist-typography-styles-headline4: mat.define-typography-level(
  $font-family: unquote("Roboto"),
  $font-size: 22px
);
$mist-typography-styles-headline5: mat.define-typography-level(
  $font-family: unquote("Roboto"),
  $font-size: 18px
);
$mist-typography-styles-headline6: mat.define-typography-level(
    $font-family: unquote("Roboto"),
    $font-size: 16px
);
$mist-typography-styles-body1: mat.define-typography-level(
    $font-family: unquote("Roboto"),
    $font-size: 12px
);
$mist-typography-styles-body2: mat.define-typography-level(
    $font-family: unquote("Roboto"),
    $font-size: 12px
);
$mist-typography-styles-subtitle1: mat.define-typography-level(
    $font-family: unquote("Roboto"),
  $font-size: 14px
);
$mist-typography-styles-subtitle2: mat.define-typography-level(
    $font-family: unquote("Roboto"),
    $font-size: 11px
);
$mist-typography-styles-button: mat.define-typography-level(
    $font-family: unquote("Roboto"),
    $font-size: 12px
);
$mist-typography-styles-overline: mat.define-typography-level(
    $font-family: unquote("Roboto"),
    $font-size: 12px
);
$mist-typography-styles-caption: mat.define-typography-level(
    $font-family: unquote("Roboto"),
    $font-size: 10px
);
$mist-typography-config: mat.define-typography-config(
  $headline-1: $mist-typography-styles-headline1,
  $headline-2: $mist-typography-styles-headline2,
  $headline-3: $mist-typography-styles-headline3,
  $headline-4: $mist-typography-styles-headline4,
  $headline-5: $mist-typography-styles-headline5,
  $headline-6: $mist-typography-styles-headline6,
  $body-1: $mist-typography-styles-body1,
  $body-2: $mist-typography-styles-body2,
  $subtitle-2: $mist-typography-styles-subtitle2,
  $caption: $mist-typography-styles-caption,
);

$mist-theme: mat.define-light-theme((color:(primary:$mist-primary, accent: $mist-accent, warn: $mist-warn),
            typography: $mist-typography-config
        ));


//$mist-typography: mat.define-typography-config($font-family: Roboto, $font-size: 85%);
@include mat.core();
@include mat.all-component-themes($mist-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100vh;
    background-color: rgb(236 236 236);
}

body {
    margin: 0;
}

.flex {
    display: flex !important;
}

.flex.column {
    flex-direction: column !important;
}

.flex.row {
    flex-direction: row !important;
}

app-root {
    height: 100vh;
    display: block;
}
